
export const COLORS = {
    mainbackground : 'black',
    maintxt : 'black',
    headerbackground : '#333',
    langselected : '#a89782',
}

export const appData = {
  lang : 'pt',
}

export const langTranslations = {
  'pt' : {
    _textapp : 'Pagina inicial do site',
    _text_biografia: <></>,
  },
  'en' : {
    _textapp : 'Site initial page',
    _text_biografia: <></>,
  }
}

export const menuItemsData = {
    'pt': [
    {
      title: "Inicio",
      url: "/",
    },
    {
      title: 'Sobre Nós',
      url: "/about",
    },
    {
      title: "Contactos",
      url: "/contactos",
    },
    {
      title: "Galeria",
      url: "/galeria",
    },
],
'en':[
{
  title: "Home",
  url: "/",
},
{
  title: 'About us',
  url: "/about",
},
{
  title: "Contacts",
  url: "/contactos",
},
{
  title: "Galery",
  url: "/galeria",
},
]
};