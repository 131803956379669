import { ImageBackground } from 'react-native-web';
import './App.css';
import TitleHeader from './components/TitleHeader';
import useLanguage from './hooks/uselanguage';

const PAGE_WIDTH = window.innerWidth;
const PAGE_HEIGHT = window.innerHeight;

function App() {

  const pt = () => {
    return(
    <>
      <div style={{"width":PAGE_WIDTH, "height":PAGE_HEIGHT-100, "padding":"2em 4em 0em 4em"}}>
        <div className='main-div' style={{display:"flex", flexDirection:'row', height: '100%'}}>
          <div style={{flex: 0.6, height: '100%'}}>
            <TitleHeader /> 
            <h1>
              Bem-vindo à DataRibeiro
            </h1><br></br>
            <p>Empresa constituída em 1986, com uma organização especializada em prestar soluções completas de serviços profissionais nas áreas de assessoria, consultoria Contabilística, Fiscal e de  Gestão.
            </p>
          </div>
          <div style={{flex:0.4, height: '100%', paddingBottom: '2em', display:'flex'}}>
            <div style={{flex:1}}>
              <img src={require('./assets/images/ini3.jpg')} style={{flex: 1, borderRadius:'5px', height:'100%', maxWidth: '100%', "object-fit":'cover'}} />
            </div>  
          </div>
        </div>
      </div>
    </>
    );
  }

  const en = () => {
    return(
    <>
      <div style={{"width":PAGE_WIDTH, "height":PAGE_HEIGHT-100, "padding":"2em 4em 0em 4em"}}>
        <div className='main-div' style={{display:"flex", flexDirection:'row', height: '100%'}}>
          <div style={{flex: 0.6, height: '100%'}}>
            <TitleHeader /> 
            <h1>
              Welcome to DataRibeiro
            </h1><br></br>
            <p>Company established in 1986, with an organization specialized in providing complete professional service solutions in the areas of advisory, accounting, tax and management consultancy.
            </p>
          </div>
          <div style={{flex:0.4, height: '100%', paddingBottom: '2em', display:'flex'}}>
            <div style={{flex:1}}>
              <img src={require('./assets/images/ini3.jpg')} style={{flex: 1, borderRadius:'5px', height:'100%', maxWidth: '100%', "object-fit":'cover'}} />
            </div>  
          </div>
        </div>
      </div>
    </>
    );
  }

  if( useLanguage().language === 'pt' )
    return pt();
  else
    return en();


  // return (
  //   <div className="App">
  //     <header className="App-header">
  //       <img src={logo} className="App-logo" alt="logo" />
  //       <p>
  //         Edit <code>src/App.js</code> and save to reload.
  //       </p>
  //       <a
  //         className="App-link"
  //         href="https://reactjs.org"
  //         target="_blank"
  //         rel="noopener noreferrer"
  //       >
  //         Learn React
  //       </a>
  //     </header>
  //   </div>
  // );
}

export default App;
