import React from 'react';
import { useLocation } from 'react-router-dom';

const TitleHeader = () => {
    

    const loc = useLocation().state;

    var title;
    
    if( loc )
        title = loc.menu;
    else
        title = (window.location.href.split("/").slice(-1)[0]);
    
    return(
        <div className='th-container' >
            <span className={(window.innerWidth>=720)?'th-text':'th-text-mobile'}><b>{title}</b></span>
        </div>
    )
    
}

export default TitleHeader;