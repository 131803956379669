import React from "react";

import useLanguage from '../hooks/uselanguage';
import TitleHeader from "../components/TitleHeader";

const About = () => {

  const pt = () => {
    return(
      <>
        <div style={{"width":window.innerWidth, "height":window.innerHeight-100, "padding":"2em 4em 0em 4em"}}>
          <div className='main-div' style={{display:"flex", flexDirection:'row', height: '100%'}}>
            <div style={{flex: 0.6, height: '100%'}}>
              <TitleHeader />
              <h1>A DataRibeiro</h1>
              <br></br>
              <p>
              Portfólio de clientes de vários sectores de atividade.
              </p><br></br>
              <p>
              Coordenação e direção de serviços prestados, nas instalações do cliente (empresas de maior dimensão), ou a partir do nosso escritório.
              </p><br></br><br></br>
              <p>
              Flexibilidade, rapidez e acompanhamento permanente da atividade do cliente.
              </p><br></br>
              <p>
              Sócios e colaboradores inscritos na Ordem CC e na Ordem dos Economistas.
              </p><br></br>
            </div>
            <div style={{flex:0.4, height: '100%', paddingBottom: '2em', display:'flex', flexDirection:'column'}}>
              <div style={{flex:0.5, display:'flex', padding:'1em', display:'flex'}}>
                <img src={require('../assets/images/OrdemEconomistas.png')} style={{flex: 1, borderRadius:'5px', height:'100%', maxWidth: '100%', "object-fit":'contain'}} />
              </div>
              <div style={{flex:0.5, padding:'1em', display:'flex'}}>
                <img src={require('../assets/images/ini1.jpg')} style={{flex: 1, borderRadius:'5px', height:'100%', maxWidth: '100%', "object-fit":'cover'}} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  const en = () => {
    return (
      <>
        <div style={{"width":window.innerWidth, "height":window.innerHeight-100, "padding":"2em 4em 0em 4em"}}>
          <div className='main-div' style={{display:"flex", flexDirection:'row', height: '100%'}}>
            <div style={{flex: 0.6, height: '100%'}}>
              <TitleHeader />
              <h1>DataRibeiro</h1>
              <br></br>
              <p>
              Portfolio of clients from various sectors of activity.
              </p><br></br>
              <p>
              Coordination and direction of services provided, at the client's premises (larger companies), or from our office.
              </p><br></br><br></br>
              <p>
              Flexibility, speed and permanent monitoring of customer activity.
              </p><br></br>
              <p>
              Members and collaborators registered with the Order CC and the Order of Economists.
              </p><br></br>
            </div>
            <div style={{flex:0.4, height: '100%', paddingBottom: '2em', display:'flex', flexDirection:'column'}}>
              <div style={{flex:0.5, display:'flex', padding:'1em', display:'flex'}}>
                <img src={require('../assets/images/OrdemEconomistas.png')} style={{flex: 1, borderRadius:'5px', height:'100%', maxWidth: '100%', "object-fit":'cover'}} />
              </div>
              <div style={{flex:0.5, padding:'1em', display:'flex'}}>
                <img src={require('../assets/images/ini1.jpg')} style={{flex: 1, borderRadius:'5px', height:'100%', maxWidth: '100%', "object-fit":'cover'}} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  if( useLanguage().language === 'pt' )
    return pt();
  else
    return en();

  //return <h1>About page content</h1>;
};

export default About;