import React from "react";

import useLanguage from '../hooks/uselanguage';
import TitleHeader from "../components/TitleHeader";

const Contactos = () => {

    const pt = () => {
        return (
            <>
            <div style={{"width":window.innerWidth, "height":window.innerHeight-100, "padding":"2em 4em 0em 4em"}}>
                <div className='main-div' style={{display:"flex", flexDirection:'row', height: '100%'}}>
                    <div style={{flex: 0.6, height: '100%'}}>
                        <TitleHeader />
                        
                        <p><b>Morada :</b><br></br>
                        Rua dos Cutileiros, Nº2850 R/C Direito<br></br>
                        Creixomil<br></br>
                        4835-044 Guimarães
                        </p><br></br><br></br>
                        <p><b>Telefone :</b><br></br>
                        +351 253 410 490<br></br>
                        +351 253 412 134
                        </p><br></br><br></br>
                        <p><b>Email :</b><br></br>
                        carlosribeiro@dataribeiro.eu<br></br>
                        ruiribeiro@dataribeiro.eu<br></br>
                        alexandraribeiro@dataribeiro.eu<br></br>
                        </p><br></br><br></br>
                        <p><b>Horário :</b><br></br>
                        Segunda a Sexta : das 08:30 às 18:00
                        </p>
                    </div>    
                    <div style={{flex:0.4, height: '100%', paddingBottom: '2em', display:'flex', flexDirection:'column'}}>
                        <div style={{flex:0.5, display:'flex', padding:'1em', display:'flex'}}>
                            <a href="https://maps.app.goo.gl/GzEPsinbT7ks8M8g9" target="_blank" style={{flex:1, display:'flex'}}>
                                <img src={require('../assets/images/mapa.png')} style={{flex: 1, borderRadius:'5px', height:'100%', maxWidth: '100%', "object-fit":'cover'}} />
                            </a>
                        </div>
                        <div style={{flex:0.5, padding:'1em', display:'flex'}}>
                            <img src={require('../assets/images/ini2.jpg')} style={{flex: 1, borderRadius:'5px', height:'100%', maxWidth: '100%', "object-fit":'cover'}} />
                        </div>
                    </div>
                </div>    
            </div>
            </>
        );
    }

    const en = () => {
        return (
            <>
            <div style={{"width":window.innerWidth, "height":window.innerHeight-100, "padding":"2em 4em 0em 4em"}}>
                <div className='main-div' style={{display:"flex", flexDirection:'row', height: '100%'}}>
                    <div style={{flex: 0.6, height: '100%'}}>
                        <TitleHeader />                        
                        <p><b>Address :</b><br></br>
                        Rua dos Cutileiros, Nº2850 R/C Direito<br></br>
                        Creixomil<br></br>
                        4835-044 Guimarães
                        </p><br></br><br></br>
                        <p><b>Phone :</b><br></br>
                        +351 253 410 490<br></br>
                        +351 253 412 134
                        </p><br></br><br></br>
                        <p><b>Emails :</b><br></br>
                        carlosribeiro@dataribeiro.eu<br></br>
                        ruiribeiro@dataribeiro.eu<br></br>
                        alexandraribeiro@dataribeiro.eu<br></br>
                        </p><br></br><br></br>
                        <p><b>Opening hours :</b><br></br>
                        Monday to Friday : from 08:30 to 18:00
                        </p>
                    </div>
                    <div style={{flex:0.4, height: '100%', paddingBottom: '2em', display:'flex', flexDirection:'column'}}>
                        <div style={{flex:0.5, display:'flex', padding:'1em', display:'flex'}}>
                            <a href="https://maps.app.goo.gl/GzEPsinbT7ks8M8g9" target="_blank" style={{flex:1, display:'flex'}}>
                                <img src={require('../assets/images/mapa.png')} style={{flex: 1, borderRadius:'5px', height:'100%', maxWidth: '100%', "object-fit":'cover'}} />
                            </a>
                        </div>
                        <div style={{flex:0.5, padding:'1em', display:'flex'}}>
                            <img src={require('../assets/images/ini3.jpg')} style={{flex: 1, borderRadius:'5px', height:'100%', maxWidth: '100%', "object-fit":'cover'}} />
                        </div>
                    </div>
                </div>
            </div>
            </>
        );
    }

    if( useLanguage().language === 'pt' )
        return pt();
    else
        return en();

  //return <h1>CONTACTOS page content</h1>;
};

export default Contactos;