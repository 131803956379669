import { Outlet } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Animated, ImageBackground } from "react-native-web";
import React, {useState, useEffect, useRef} from 'react'


export default function Root() {

  const [backgroundImage, setBackgroundImage] = useState(require('../assets/images/ini5.jpg'));

  const allImages = [
    require('../assets/images/ini1.jpg'),
    require('../assets/images/ini2.jpg'),
    require('../assets/images/ini3.jpg'),
    require('../assets/images/ini4.jpg'),
    require('../assets/images/ini5.jpg'),
  ]

  const updateBackground = () => {
    fadeOut()
    let randomImage = parseInt( Math.random() * ( allImages.length - 1 ) );
    setTimeout( () => {setBackgroundImage(allImages[randomImage]); fadeIn()}, 2000 );
    //setBackgroundImage(allImages[randomImage]);
  }

//   useEffect(() => {
//     const intervalId = setInterval(() => {
//         updateBackground();
//     }, 10000)
    
//     return () => clearInterval(intervalId);
// }, [])


const fadeAnim = useRef(new Animated.Value(0)).current;
  
const fadeIn = () => {
  // Will change fadeAnim value to 1 in 5 seconds
  Animated.timing(fadeAnim, {
    toValue: 1,
    duration: 2000,
    useNativeDriver: true,
  }).start();
};

const fadeOut = () => {
  // Will change fadeAnim value to 0 in 3 seconds
  Animated.timing(fadeAnim, {
    toValue: 0,
    duration: 2000,
    useNativeDriver: true,
  }).start();
};

//fadeIn();
  
  // return (
  //   <>
  //   <ImageBackground
  //       source={backgroundImage}
  //       resizeMode='cover'
  //     >
  //     <div>
  //       <Header />
  //       <hr className="main-hr"></hr>
  //       <div className="content">
  //         <Outlet />
  //       </div>
  //       <div
  //         style={{width:'100vw'}}
  //       >
  //       <Footer />
  //       </div>
  //     </div>
  //     </ImageBackground>
  //   </>
  // );

  // return (
  //   <>

  //     <div>
  //       <Header />
  //       <hr className="main-hr"></hr>
  //       <div className="content">
  //         <Outlet />
  //       </div>
  //       <div
  //         style={{width:'100vw'}}
  //       >
  //       <Footer />
  //       </div>
  //     </div>
  //   </>
  // );

  return (
    <>

      <div>
        <Header />
        <hr className="main-hr"></hr>
        <div className="content">
          <Outlet />
        </div>
        <div
          style={{width:'100vw'}}
        >
        </div>
      </div>
    </>
  );
}