import React from "react";

import useLanguage from '../hooks/uselanguage';
import TitleHeader from "../components/TitleHeader";

import Carousel from 'react-native-banner-carousel';

import {
    View,
    Image
} from 'react-native-web'

const Galeria = () => {

    const gal = [
        require('../assets/images/galeria/2.jpg'),
        require('../assets/images/galeria/3.jpg'),
        require('../assets/images/galeria/4.jpg'),
        require('../assets/images/galeria/5.jpg'),
        require('../assets/images/galeria/6.jpg'),
        require('../assets/images/galeria/20200923_102502.jpg'),
        require('../assets/images/galeria/20200924_133247.jpg'),
        require('../assets/images/galeria/20200924_133334.jpg'),
        require('../assets/images/galeria/20200924_133347.jpg'),
        require('../assets/images/galeria/20200924_133409.jpg'),
        require('../assets/images/galeria/20200924_133422.jpg'),
        require('../assets/images/galeria/20200924_133630.jpg'),
    ]

    const renderImage = (imgdata, index) => {
        return(
            <View key={index} style={{height:'600px', width:'100%', backgroundColor:'transparent'}}>
                <Image source={imgdata} style={{ flex:1, width:'92%', height:'300px'}} />
            </View>
        )
    }


    const pt = () => {
        return (
            <>
            <div style={{"width":window.innerWidth, "height":window.innerHeight-100, "padding":"2em 4em 0em 4em"}}>
                <TitleHeader />
                <div className="main-div" style={{backgroundColor:'transparent'}}>
                    <Carousel 
                        width='100%'
                        autoplay
                        autoplayTimeout={5000}
                        loop
                        index={0}
                        pageSize={window.innerWidth}
                    >
                        {
                            gal.map(
                                ( img, index ) => {
                                    return renderImage(img, index);
                                }
                            )
                        }
                    </Carousel>
                </div>
            </div>
            </>
        );
    }

    const en = () => {
        return (
            <>
            <div style={{"width":window.innerWidth, "height":window.innerHeight-100, "padding":"2em 4em 0em 4em"}}>
                <TitleHeader />
                <div className="main-div" style={{backgroundColor:'transparent'}}>
                    <Carousel 
                        width='100%'
                        autoplay
                        autoplayTimeout={5000}
                        loop
                        index={0}
                        pageSize={window.innerWidth}
                    >
                        {
                            gal.map(
                                ( img, index ) => {
                                    return renderImage(img, index);
                                }
                            )
                        }
                    </Carousel>
                </div>
            </div>
            </>
        );
    }

    if( useLanguage().language === 'pt' )
        return pt();
    else
        return en();

  //return <h1>CONTACTOS page content</h1>;
};

export default Galeria;